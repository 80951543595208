<ion-grid>
  <ion-row>
    <ion-col>
      <ion-card class="bg-white">
        <ion-card-content>
          <ion-card-title class="flex justify-content-space-between">
            <span>Search for Signed Documents:</span>
            <span><small class="subtle fs-16">Click document name to select</small></span>
          </ion-card-title>
          <div class="py-3">
            <ion-searchbar
              showCancelButton="never"
              autocomplete="on"
              placeholder="Search for the client's name or email..."
              [value]="searchValue"
              (ionChange)="searchForSignedDocuments($event)"
            ></ion-searchbar>
            <ion-list>
              <ion-item *ngIf="!(signedDocumentsService.signedDocuments$ | async )?.length" class="text-center">
                <ion-label class="text-center text-muted fw-300">No results. Please modify your search parameters above</ion-label>
              </ion-item>
              <signed-document-item
                [showBackground]="true"
                [allowMultiple]="allowMultiple"
                iconName="radio-button-off"
                [documents]="(signedDocumentsService.signedDocuments$ | async)"
                (documentSelected)="handleDocumentSelection($event)"
              ></signed-document-item>
            </ion-list>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="signedDocumentsService.selectedDocuments.length && allowMultiple">
    <ion-col>
      <selected-documents
        (complete)="continue($event)"
      ></selected-documents>
    </ion-col>
  </ion-row>
</ion-grid>

