import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {GlobalsService} from '../../globals.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {IExternalDocument, IMissingFieldError} from '../../models/external_document';
import {tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { IPdfDocument } from 'src/app/models/pdf_document';

//TODO: Understand garbage collection of this service! (as well as send for signature service)

@Injectable()
export class ExternalDocumentsService implements OnDestroy {
  base_url: string;

  private externalDocuments = new BehaviorSubject(<IExternalDocument[]>[])
  externalDocuments$ = this.externalDocuments.asObservable()

  private refreshDocuments = new BehaviorSubject(<boolean>(null))
  refreshDocuments$ = this.refreshDocuments.asObservable()

  constructor(private globals: GlobalsService,
              private http: HttpClient) {
    this.base_url = this.globals.base_url;
  }

  ngOnDestroy() {
    console.log("DESTROY")
    this.setExternalDocuments([])
  }


  /**
   * Loads all external documents from the database
   */
  fetchDocuments(use_case: 'to_embed'|'in_person', location_name: string = null): Observable<IExternalDocument[]> {
    let url = `${this.base_url}/external_documents?purpose=${use_case}&location_name=${location_name}`
    return this.http.get<IExternalDocument[]>(url, { headers: { 'Api-Key': environment.teamFacingApiKey }}).pipe(
      tap(documents => {
        console.log(documents)
        this.setExternalDocuments(documents)
      })
    )
  }

  setRefreshDocuments(value: boolean) {
    this.refreshDocuments.next(value)
  }


  fetchRequiredFields(client_id: number, document_id: number, program_id: number = null): Observable<IMissingFieldError[]> {
    let url = `${this.base_url}/clients/${client_id}/external_documents/${document_id}/fetch_required_fields?program_id=${program_id}`
    return this.http.get<IMissingFieldError[]>(url, { headers: { 'Api-Key': environment.teamFacingApiKey }})
  }


  setExternalDocuments(external_documents: IExternalDocument[]): void {
    this.externalDocuments.next(Object.assign([], external_documents))
  }


  /**
   * Sends the client the {@link selectedDocuments} to send.  If the team member adds formValues, the client is updated
   * and the novel fields and new values are returned for use.
   * @param client_id
   * @param external_document_ids
   * @param formValues
   */
  sendForSignature(client_id: number, external_document_ids: number[], formValues: any): Observable<{errors?: string[], updatedFields?: any}> {
    let url = `${this.base_url}/clients/${client_id}/external_documents/send_for_signature`
    return this.http.post<{errors?: string[], updatedFields?: any}>(url, {form_values: formValues, document_ids: external_document_ids})
  }

  generateFormParams(externalDocument: IExternalDocument, formValues, client_id = null): IPdfDocument {
    console.log(formValues);

    const email = formValues['user.email'] || formValues['email']
    const name = formValues['name'] || formValues['client_name'] || `${formValues['client.first_name']} ${formValues['client.last_name']}`

    return {
      client_id,
      version: 1,
      metadata: formValues,
      recipient_name: name,
      recipient_email: email,
      name: externalDocument.name,
      visible_to_client: !!client_id
    }
  }

  displayPdfDocument(pdf_document: IPdfDocument): Observable<Blob> {
    const url = `${this.globals.base_url}/pdf_documents/${pdf_document.id}`;
    return this.http.get(url, {
      headers: { 'Api-Key': environment.teamFacingApiKey },
      responseType: 'blob'
    })
  }


  fetchPdfOfExternalDocument(externalDocument: IExternalDocument, formValues, program_id = null): Observable<Blob> {
    const url = `${this.globals.base_url}/external_documents/${externalDocument.id}/pdf_documents/pdf_download?program_id=${program_id}`;
    const data = { pdf_document: this.generateFormParams(externalDocument, formValues) }
    return this.http.post(url, data, {
      headers: { 'Api-Key': environment.teamFacingApiKey },
      responseType: 'blob'
    })
  }

  fetchHtmlOfExternalDocument(externalDocument: IExternalDocument, formValues, program_id = null): Observable<any> {
    const url = `${this.globals.base_url}/external_documents/${externalDocument.id}/pdf_documents/pdf_download?program_id=${program_id}`;
    const data = { pdf_document: this.generateFormParams(externalDocument, formValues) }
    return this.http.post(url, data, {
      headers: { 'Api-Key': environment.teamFacingApiKey },
      responseType: 'text'
    })
  }

  sendDocumentToClient(externalDocument: IExternalDocument, formValues, progam_id = null, client_id = null): Observable<void> {
    const url = `${this.globals.base_url}/external_documents/${externalDocument.id}/pdf_documents/send_to_recipient?program_id=${progam_id}`;
    const data = { pdf_document: this.generateFormParams(externalDocument, formValues, client_id) }
    return this.http.post<void>(url, data, { headers: { 'Api-Key': environment.teamFacingApiKey } } )
  }

  sendExistingDocumentToClient(pdfDocument: IPdfDocument) {
    const url = `${this.globals.base_url}/external_documents/${pdfDocument.external_document.id}/pdf_documents/send_to_recipient`
    const data = { pdf_document: pdfDocument }
    return this.http.post<void>(url, data, { headers: { 'Api-Key': environment.teamFacingApiKey } } )
  }
}
