<ion-modal [isOpen]="displayModal" class="modal-fullscreen" #modal>
  <ng-template>
    <ion-content [fullscreen]="true">
      <ion-card class="bg-white fast p-0 m-0" *ngIf="selectedDocument">
        <e-signature-card
          [selectedDocument]="selectedDocument"
          [formattedUrl]="formattedUrl"
          [client]="client"
          (close)="handleDocumentSigning($event)"
        ></e-signature-card>
      </ion-card>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-grid>
  <ion-row>
    <ion-col>
      <a180-common-card
        [title]="cardTitle"
        helpText="Pull down to refresh">
        <ion-refresher slot="fixed" (ionRefresh)="fetchDocuments($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <ion-list *ngIf="selectedDocument">
          <ion-item color="success">
            <strong>{{selectedDocument.name}}</strong>
            <ion-button slot="end" fill="clear" (click)="selectDocument(null)">
              <ion-icon name="close" color="danger"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-list>
        <ion-list class="mt-4" [@listStagger]='(externalDocumentService.externalDocuments$ | async)?.length'>
          <ion-item-sliding class="documentRowSlider shaded-slider" *ngFor="let item of externalDocumentService.externalDocuments$ | async | filterBy:'name':selectedDocument?.name:'does-not-equal'">
            <ion-item button class="documentRow ion-text-wrap" style="background:none;" [disabled]="item.signed_at">
              <ion-icon
                *ngIf="client"
                [name]="item.signed_at ? 'checkmark-circle' : 'close-circle'"
                [color]="item.signed_at ? 'success' : 'danger'"
                slot="start"
              ></ion-icon>
              <ion-label (click)="selectDocument(item)" class="pointer">
                <div class="flex align-items-center">
                  <h2 class="pb-1">
                    {{ item.name | titleize | capitalize }}
                  </h2>
                </div>
                <p *ngIf="client && item.highestStatus">
                  {{item.highestStatus.field | titleize | capitalize}}: {{item.highestStatus.date | date:'short'}}
                </p>
              </ion-label>
            </ion-item>
          </ion-item-sliding>
        </ion-list>
      </a180-common-card>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid @fadeInOutUpDown *ngIf="selectedDocument && !client">
  <ion-row>
    <ion-col class="text-center">
      <ion-button color="light" (click)="documentInput='search'">Search For Existing Document</ion-button>
      <ion-text color="light" class="px-3">- or -</ion-text>
      <ion-button color="light" (click)="fillOutDocument()">Fill Out New Document</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>

<a180-signed-document-selector
  @fadeInOutUpDown *ngIf="documentInput === 'search'"
  [allowMultiple]="false"
  (continueProcessing)="getPdfDocument()"
></a180-signed-document-selector>

<ion-grid>
  <ion-row @fadeInOutUpDown *ngIf="documentInput === 'newDoc' || selectedPdf">
    <ion-col>
      <a180-common-card title="Enter the Following Values:" helpText="Populate values for the document">
        <a180-missing-fields-form
          [allowNotes]="false"
          [submitButtonText]="submitButtonText"
          (form_submission)="openDocument($event, false, 'html')"
          #missingFieldsForm
          ></a180-missing-fields-form>
        <ion-row>
          <ion-col class="ion-text-center" *ngIf="selectedDocument?.esign_enabled">
            <ion-button color="danger" [disabled]="!formValues" (click)="displayDocument(this.selectedDocument)">
              ESign Document
            </ion-button>
          </ion-col>
          <ion-col class="ion-text-center" *ngIf="selectedDocument?.pdf_enabled">
            <ion-button [disabled]="!formValues" (click)="openDocument(this.formValues, true, 'html')">
              View {{ selectedDocument?.name || 'PDF' }}
            </ion-button>
          </ion-col>
        </ion-row>
      </a180-common-card>
    </ion-col>
  </ion-row>
</ion-grid>



<ion-grid>
  <ion-row *ngIf="displayModal">
    <ion-col>
      <ion-card class="bg-white fast p-0 m-0" *ngIf="selectedDocument">
        <e-signature-card
          [selectedDocument]="selectedDocument"
          [formattedUrl]="formattedUrl"
          [client]="client"
          (close)="handleDocumentSigning($event)"
        ></e-signature-card>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
