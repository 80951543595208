import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {

  /**
   * Sorts the provided array by the provided field in ascending order.  Pass true to the reverse field to sort in descending order.
   * @param collection array of objects to be filtered
   * @param key key to filter for
   * @param value value to filter for
   * @param comparison how to compare. Defaults to equals.
   */
  transform(collection: any, key: string, value: string, comparison = 'equals'): any[] {
    if (!Array.isArray(collection)) {
      return;
    }

    let response = null

    switch(comparison) {
      case 'does-not-equal':
        response = collection.filter(element => element[key] !== value)
        break;

      case 'equals':
        response = collection.find(element => element[key] === value)
        break;

      case 'equals-list':
        response = collection.filter(element => element[key] === value)
        break;

      case 'greater than':
        response = collection.filter(element => element[key] > value)
        break;

      case 'less than':
        response = collection.filter(element => element[key] < value)
        break;
    }

    return response
  }
}
